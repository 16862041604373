footer {
  width: calc(100% - 2 * var(--primaryPadding));
  margin-right: var(--primaryPadding);
  margin-bottom: var(--secondaryPadding);
  margin-left: var(--primaryPadding);
  padding-top: 1rem;

  background-color: var(--backgroundColor);
  border-top: 1px solid var(--rullerColor);

  a {
    border-bottom: 1px solid var(--rullerColor);

    &:hover, &:focus {
      color: var(--accentColor);

      border-bottom-color: var(--rullerHoverColor)
    }

    &:focus {
      background-color: var(--rullerColor);
      border-bottom: 0;
      outline: 0;
      box-shadow: 0 0 0 2px var(--accentColor)
    }
  }
  
  span {
    display: block;
    margin-top: 1rem;

    font-size: 10vw
  }

  ul {
    padding-left: 2rem;

    list-style-type: none;

    li {
      margin-top: 0.25rem;

      &::before {
        content: "*"
      }
    }
  }
}