:root {
  /* Background color */
  --backgroundColor: #f9f5f1;

  /* Foreground colors */
  --foregroundColor: #073642;
  --rullerColor: #07364226;

  /* Accent colors */
  --accentColor: #cb4b16;
  --rullerHoverColor: #cb4b1626;
  --headerLinksColor: #cb4b1625;
  --tableBackgroundColor: #cb4b1609;

  /* Chemistry table elements color */
  --nonmetalsColor: #558b2f;
  --nobleGasesColor: #00838f;
  --alkaliMetalsColor: #6a1b9a;
  --alkalineEarthMetalsColor: #0277bd;
  --metalloidsColor: #ef6c00;
  --halogensColor: #d84315;
  --postTransitionMetalsColor: #9e9d24;
  --transitionMetalsColor: #c62828;
  --lanthanidesColor: #008e76;
  --actinidesColor: #0088a3;
  --trashColor: #424242;

  --primaryPadding: 1.875rem;
  --secondaryPadding: 3rem
}

@mixin mobile {
  :root {
    --primaryPadding: 1.17rem;
    --secondaryPadding: 3rem
  }
}

@mixin dark {
  :root {
    /* Background color */
    --backgroundColor: #000000;

    /* Foreground colors */
    --foregroundColor: #ffffff;
    --rullerColor: #ffffff26;

    /* Accent colors */
    --accentColor: #cb4b16;
    --rullerHoverColor: #cb4b1626;
    --headerLinksColor: #cb4b1640;
    --tableBackgroundColor: #cb4b1620;

    /* Chemistry table elements color */
    --nonmetalsColor: #85bb5c;
    --nobleGasesColor: #4fb3bf;
    --alkaliMetalsColor: #9c4dcc;
    --alkalineEarthMetalsColor: #58a5f0;
    --metalloidsColor: #ff9d3f;
    --halogensColor: #ff7543;
    --postTransitionMetalsColor: #d2ce56;
    --transitionMetalsColor: #ff5f52;
    --lanthanidesColor: #5df2d6;
    --actinidesColor: #62ebff;
    --trashColor: #c2c2c2;

    p, ul, footer span {
      color: #ffffff80
    }
  }
}

@media screen and (min-width: 1200px) {
  body {
    padding: 0 5%
  }
}

*, *::after, *::before {
  box-sizing: border-box
}

* {
  scroll-behavior: smooth
}

*::selection {
  color: var(--foregroundColor);

  background-color: var(--accentColor)
}

*::-moz-selection {
  color: var(--foregroundColor);

  background-color: var(--accentColor)
}

html {
  font-size: 16px
}

body {
  margin: 0;
  overflow-x: hidden;

  background-color: var(--backgroundColor)
}

main {
  margin: 0 auto;

  background-color: var(--backgroundColor)
}

h1,
p,
table {
  font-weight: 500
}

h1,
p,
th,
td,
sup,
sub {
  font-size: 1rem
}

h1 {
  margin: 0
}

h2,
h3,
p {
  a {
    font-weight: inherit
  }
}

h2, h3, h4 {
  font-weight: 700
}

h2,
h3 {
  color: var(--accentColor);
  font-size: 2rem;
  line-height: 1em;
  letter-spacing: -0.02em;

  a {
    &:hover {
      color: var(--foregroundColor);

      border-bottom: 1px solid var(--rullerColor)
    }
    
    &:focus {
      color: var(--foregroundColor);

      background-color: var(--rullerColor);
      border-bottom: 0;
      outline: 0;
      box-shadow: 0 0 0 2px var(--foregroundColor)
    }
  }
}


h2 {
  display: table;
  margin-top: 0;
  margin-bottom: 1.5rem;

  text-transform: uppercase;

  a {

    &:hover, &:focus {
      svg {
        transition: none;

        fill: var(--foregroundColor)
      }
    }
    svg {
      transition: fill cubic-bezier(0.07, 0.74, 0.38, 1.01) 0.5s
    }
  }
}

h2 svg {
  height: 100%;

  vertical-align: top;

  fill: var(--accentColor)
}

h2 + h3 {
  margin-top: 1.5rem
}

h3 {
  margin-top: 2.5rem;
  margin-bottom: 0;
  scroll-margin-top: 2.5rem;

  font-weight: normal
}

h4 {
  margin-top: 0;
  margin-bottom: 0
}

h4, p, ul, footer span {
  color: var(--foregroundColor);
  line-height: 1.4em
}

p, ul {
  max-width: 500px;
  margin-top: 0;
  margin-bottom: 0
}

a {
  color: inherit;
  text-decoration: none;

  transition: color cubic-bezier(0.07, 0.74, 0.38, 1.01) 0.5s,
    background-color cubic-bezier(0.07, 0.74, 0.38, 1.01) 0.5s,
    box-shadow 0.2s;

  &:hover, &:focus {
    transition: none
  }
}

td {
  color: var(--foregroundColor)
}
         

sup, sub {
  vertical-align: initial
}

sup {
  font-feature-settings: "case" 1, "tnum" 1, "sups" 1
}

sub {
  font-feature-settings: "case" 1, "tnum" 1, "subs" 1
}

section.main__maths,
section.main__phys,
section.main__chem {
  padding: 0 var(--primaryPadding) var(--secondaryPadding)
    var(--primaryPadding)
}

section.navigation {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, auto));
  gap: 1.5rem;

  article {

    vertical-align: top;

    ul {
      margin: 0;
      padding: 0;

      li {
        margin-top: 0.4rem;

        list-style: none;

        a {
          border-bottom: 1px solid var(--rullerColor);

          &:hover, &:focus {
            color: var(--accentColor);

            border-bottom-color: var(--rullerHoverColor)
          }

          &:focus {
            color: var(--accentColor);

            background-color: var(--rullerColor);
            border-bottom: 0;
            outline: 0;
            box-shadow: 0 0 0 2px var(--accentColor)
          }
        }
      }
    }
  }
}

img {
  position: relative;
  left: 50%;

  width: 100%;
  max-width: 600px;
  padding: 0 var(--primaryPadding);

  transform: translateX(-50%)
}

hr {
  height: 1px;
  margin: 0 0 1rem 0;

  background-color: var(--rullerColor);
  border: 0
}