section.table-container {
  position: relative;

  margin-top: 1rem;
  margin-right: calc(-1 * var(--primaryPadding));
  margin-left: calc(-1 * var(--primaryPadding));
  overflow-x: scroll;
  contain: content;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  -webkit-mask-image: linear-gradient(90deg,rgba(0,0,0,0) 0,rgba(0,0,0,.25) calc(var(--primaryPadding)/2),rgba(0,0,0,1) var(--primaryPadding),rgba(0,0,0,1) calc(100% - var(--primaryPadding)),rgba(0,0,0,.25) calc(100% - var(--primaryPadding)/2),rgba(0,0,0,0));
  mask-image: linear-gradient(90deg,rgba(0,0,0,0) 0,rgba(0,0,0,.25) calc(var(--primaryPadding)/2),rgba(0,0,0,1) var(--primaryPadding),rgba(0,0,0,1) calc(100% - var(--primaryPadding)),rgba(0,0,0,.25) calc(100% - var(--primaryPadding)/2),rgba(0,0,0,0));

  &::-webkit-scrollbar {
    height: 0
  }

  table {
    display: inline-flex;
    margin: 0 var(--primaryPadding);
    padding: 0.25rem 1rem;

    background-color: var(--tableBackgroundColor);
    border-radius: 0.25rem;
    border-collapse: collapse;
    font-feature-settings: "case" 1, "tnum" 1;

    &.right td,
    &.right tr:first-of-type th:not(:first-child),
    &.part-right tr th:not(:first-of-type),
    &.part-right tr td:not(:first-of-type),
    &.td-right td {
      text-align: right
    }

    tr:not(:last-child) {
      border-bottom: 1px solid var(--rullerColor)
    }

    tr {
      &:empty {
        display: none
      }

      th:not(:last-child), td:not(:last-child) {
        padding-right: 1.25rem
      }

      th,
      td {
        max-width: 12.5rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-left: 0;

        line-height: 1em;
        vertical-align: top
      }

      th {
        color: var(--foregroundColor);
        text-align: left
      }

      td p {
        margin: 0
      }
    }
  }
}

.table-caption {
  max-width: 500px;
  margin-top: 0.75rem;

  vertical-align: top;

  &:empty {
    display: none
  }
}

