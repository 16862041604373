@font-face {
  font-weight: 500;
  font-family: "PT Root UI";
  font-style: normal;
  src: url("./pt-root-ui_medium.woff2") format("woff2");
  font-display: swap
}

@font-face {
  font-weight: 700;
  font-family: "PT Root UI";
  font-style: normal;
  src: url("./pt-root-ui_bold.woff2") format("woff2");
  font-display: swap
}

h1,
h2,
h3,
h4,
a,
table,
p,
ul {
  font-family: "PT Root UI", Helvetica, Arial, sans-serif
}