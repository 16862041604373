table.mendeleev {
  border: 0;

  th {
    text-align: center;
    vertical-align: middle
  }

  td {
    padding-bottom: 1rem;

    span {
      display: block;

      font-size: 0.75em
    }

    span:nth-child(2) {
      font-weight: 700;
      font-size: 2rem;
      line-height: 1em
    }
  }
}

td {
  &.nonmetals {
    color: var(--nonmetalsColor)
  }

  &.noble-gases {
    color: var(--nobleGasesColor)
  }

  &.alkali-metals {
    color: var(--alkaliMetalsColor)
  }

  &.alkaline-earth-metals {
    color: var(--alkalineEarthMetalsColor)
  }

  &.metalloids {
    color: var(--metalloidsColor)
  }

  &.halogens {
    color: var(--halogensColor)
  }

  &.post-transition-metals {
    color: var(--postTransitionMetalsColor)
  }

  &.transition-metals {
    color: var(--transitionMetalsColor)
  }

  &.lanthanides {
    color: var(--lanthanidesColor)
  }

  &.actinides {
    color: var(--actinidesColor)
  }

  &.trash {
    color: var(--trashColor)
  }
}