header {
  width: calc(100% - 2 * var(--primaryPadding));
  margin-right: var(--primaryPadding);
  margin-bottom: var(--secondaryPadding);
  margin-left: var(--primaryPadding);

  background-color: var(--backgroundColor);

  section, nav {
    display: flex
  }

  section {
    justify-content: space-between;
    scrollbar-width: none
  }
  
  nav {
    margin-top: 1rem;
    
    &:first-child, &:last-child a:not(:last-child) {
      margin-right: 0.065rem
    }

    &:first-child a {
      margin-right: 1rem;

      background-color: unset;
      border: 1px solid var(--rullerColor);

      pointer-events: none
    }

    &:last-child a {
      background-color: var(--headerLinksColor);
      border: 1px solid transparent;

      &:hover, &:focus {
        background-color: var(--tableBackgroundColor)
      }
      
      &:last-child {
        margin-left: 1rem;

        border-radius: 1rem
      }
    }

    a {
      display: inline-block;
      padding: 0.185rem 0.435rem;

      color: var(--foregroundColor);
      font-weight: 500;
      line-height: 1.5em;

      border-radius: 0.35rem;

      &:focus {
        color: var(--accentColor);

        outline: 0;
        box-shadow: inset 0 0 0 0.125rem var(--accentColor)
      }
      
      span {
        opacity: 0.5
      }
    }
  }

  p {
    margin: 1rem 0.5rem;

    font-family: Georgia, serif;
    font-style: italic
  }
}


@mixin mobile {

  header {

    section {
      justify-content: unset;
      margin-right: calc(-1 * var(--primaryPadding));
      margin-left: calc(-1 * var(--primaryPadding));
      padding-right: var(--primaryPadding);
      padding-left: var(--primaryPadding);
      overflow: scroll;
      -webkit-mask-image: linear-gradient(90deg,rgba(0,0,0,0) 0,rgba(0,0,0,.25) calc(var(--primaryPadding)/2),rgba(0,0,0,1) var(--primaryPadding),rgba(0,0,0,1) calc(100% - var(--primaryPadding)),rgba(0,0,0,.25) calc(100% - var(--primaryPadding)/2),rgba(0,0,0,0));
      mask-image: linear-gradient(90deg,rgba(0,0,0,0) 0,rgba(0,0,0,.25) calc(var(--primaryPadding)/2),rgba(0,0,0,1) var(--primaryPadding),rgba(0,0,0,1) calc(100% - var(--primaryPadding)),rgba(0,0,0,.25) calc(100% - var(--primaryPadding)/2),rgba(0,0,0,0))
    }

    section::-webkit-scrollbar {
      display: none
    }

    nav {
      &:first-child a {
        margin-right: 0
      }

      &:last-child {
        padding-right: var(--primaryPadding);

        a:last-child {
          margin-left: 0
        }
      }
    }

    nav a span {
      display: none
    }

    p {
      margin: 1rem 0
    }
  }
}